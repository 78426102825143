h2 {
    font-family: "Roboto Condensed";
    font-weight: 400;
}

h2 {
    margin: 0;
    font-size: 40px;
    font-weight: normal;
    text-align: center;
}