@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:wght@300&display=swap');

.screen-reader-only {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  width: 1px;
  height: 1px;
}
a, a:visited, a:hover, a:active {
  appearance: none;
  color: inherit; 
 }
 
 a.inline-link, .sicce-link-button a {
   font-family: "Roboto Condensed";
   font-weight: 400;
 }

 .sicce-link-button a {
  font-weight: 700;
}



.app-container { 
  font-family: "Roboto";
  color: white;
  font-weight: 300;
  padding-top: 25px;
  font-size: 16px;
  line-height: 1.15;
  position: relative;
}
.app-background ~ *{

  position: relative;

}
.app-background {

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
}
.app-background img {

  width: 100%;
  height: 100%;
  object-fit: cover;

}
main,
.c-column {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

}
main {
  gap: 55px 0px;
  --main-pad: max(15px, 5vw);
  padding: 45px var(--main-pad) 70px var(--main-pad);
  text-align: center;
}
.c-column {

  gap: 35px 0px;

}
main section {
  width: 100%;
}


main .h-spanner {
  max-width: 570px;


}



.sicce-shadow, .sicce-shadow-group > * {
  box-shadow: 0 4px 10px rgba(0,0,0,.35);
}

.sicce-card {

  border-radius: 25px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.10) 100%);

  backdrop-filter: blur(2.5px);

  gap: 24px;

  max-width: 344px;
  width: 100%;
  padding: 41px 0 50px 0;

}

.sicce-card-shadow {
  filter: drop-shadow(0 4px 10px rgba(0,0,0,.1)) drop-shadow(0 1px 3px rgba(0,0,0,.4));

}
.sicce-card-buttons {

  gap: 8px;;

}


.sicce-link-button {

  
  border-radius: 100px;
  width: 100%;
  box-sizing: border-box;
  max-width: 343px;

  background:  linear-gradient(135deg, #00B0E6 0%, #004D69 100%);
  
  

  
  overflow: hidden;
}

.sicce-link-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: inherit;
  text-decoration: none;
  column-gap: 8px;
  font-size: 20px;
  padding: 16px;

}




.sicce-social-row {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;



  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}

.sicce-social-row li {
  border-radius: 100%;
  background: white;
  width: 50px;
  height: 50px;
  overflow: hidden;

}
.sicce-social-row a {
  display: block;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}