header {

    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    padding: 0 max(18px, 35% - 130px);
  
    z-index: 2000;
  }


  .header-share {

    cursor: pointer;

  }