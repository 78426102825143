.language-option {

    display: flex;
    gap: 8px;

}
.language-option span {

   margin: auto

}