.language-select {

    position: relative;

    font-family: "Roboto Condensed";
    font-size: 15px;
    font-weight: 400;

    row-gap: 10px;

    min-width: 90px;
    box-sizing: border-box;

    display: grid;

    grid-template-rows: max-content 0;
}

.language-select-box {

    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: #2B3D47;
    padding: 10px 11px;

}

.language-select-box.transparent {

    background: rgba(43, 61, 71, 0.50);
    backdrop-filter: blur(2px);

}

.language-select-preview {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    pointer-events: none;
}

.language-select ul {
    pointer-events: none;
    margin: 0;
    top: 100%;
    appearance: none;
    list-style: none;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;

    height: max-content;

    display: flex;
    flex-flow: column;

    visibility: hidden;
    opacity: 0;
    transform: scaleY(.6);
    transform-origin: 50% 0%;
    transition: visibility 0s .2s, opacity .2s ease-in-out, transform .2s cubic-bezier(1,.02,.88,.45);

}

.language-select.expanded ul {
    pointer-events: inherit;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition: visibility 0s, opacity .2s ease-in-out, transform .15s cubic-bezier(.17,.67,.07,.92);

}

.language-select li {
appearance: none;
}

.language-select li a {
    appearance: none;
    text-decoration: none;
    color: inherit;
    padding: 10px 0;
    display: block;
    
    }